.question-view{
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
/* .question-view-md{
    max-width: 600px;
}
.question-view-lg{
    max-width: 970px;
} */

.question-answer-item-handler{
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
  display: block;
  width: 100%;
  margin-bottom: 9px;
}

.question-view .question-headline{
    color: #000;
    font-size: 31px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
}

.question-view .question-answer-handler{
  width: 100%;
}

.question-view .question-answer-handler textarea{
  height: 100px;
  width: 100%;
  max-width: 500px;
  border: 1px solid #9f9f9f;
  margin-bottom: 20px;
  box-shadow: none;
}

.question-view .question-answer-handler .textarea-checker .next-btn{
  text-align: center;
}

.question-view .question-answer-handler .textarea-checker button{
  background-color: #9ee20b;
  border: none;
  padding: 20px 20px;
  font-size: 20px;
  border-radius: 23px;
  color: #000;
  font-weight: 300;
}

.question-view .question-item{
    width: 100%;
    height: 45px;
    background-color: #f0f0f0;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    color: #000;
    font-weight: 400;
    flex-direction: column;
    border: 1px solid #dddbdb;
}

.question-view .question-item i{
    width: 80px;
    height: auto;
    display: block;
    margin-bottom: 10px;
}

.question-view .question-item:hover{
    background-color: #d4d4d4;
}

.formular-wrapper{
  animation-name: moveIn;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.question-view.animation-start{
  animation-name: move;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.question-view.animation-stop{
  animation-name: moveIn;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes move {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes moveIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.question-answer-item-handler{
    flex: 0 0 40%;
}

.question-answer-handler-checkbox{

}

.question-answer-handler-checkbox .question-answer-item-handler input{
  display: none;
}

.question-answer-handler-checkbox .question-answer-item-handler input[type="checkbox"]:checked+label{ 
  background-color: #26a8ab;
  color: #FFFFFF;

} 

.question-answer-handler-checkbox .question-answer-item-handler input[type="checkbox"]:checked+label:after{
  display: block;
}

.question-answer-handler-checkbox .question-answer-item-handler label{
  width: 100%;
  background: #ececec;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  display: block;
  padding: 12px;
  cursor: pointer;
  position: relative;
  display: flex;

}

.question-answer-handler-checkbox .question-answer-item-handler label:before{
  content: '';
  width: 20px;
  height: 20px;
  background-color: rgb(37, 37, 37);  
  margin-right: 10px;
}

.question-answer-handler-checkbox .question-answer-item-handler label:after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0303 8.78039L8.99993 16.8107L5.4696 13.2804L6.53026 12.2197L8.99993 14.6894L15.9696 7.71973L17.0303 8.78039Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
  background-size: 100%;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 8px;
  left: 10px;
  display: none;
}

.question-answer-handler-checkbox .question-answer-item-handler .checkbox-item{

  margin: 0;
}

.question-answer-handler-checkbox .question-answer-item-handler .checkbox-item label{

}


.question-answer-handler-checkbox label{
    color: #244455;
    font-size: 19px;
    font-weight: 400;
}

.question-answer-handler-checkbox .checkbox-item{
    margin-bottom: 20px;
}

.submit-handler{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.next-btn,.back-btn{
  margin: 0 10px;
}
.next-btn button{
    background-color: #01a8d4;
    color: #ffffff;
    border-radius: 30px;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    font-weight: 800;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.back-btn button{
  text-align: center;
  margin-bottom: 20px;
}

.back-btn button{
  background-color: #93999a!important;
  color: #ffffff!important;
  border-radius: 30px;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  font-weight: 800;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.back-btn button:hover{
  background: #4b4b4b;
}



/* Checkbox */

/* .container-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  

  .container-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  .container-box:hover input ~ .checkmark {
    background-color: #ccc;
  }
  

  .container-box input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
 
  .container-box input:checked ~ .checkmark:after {
    display: block;
  }

  .container-box .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } */