.progress-wrap{
    margin: 40px 25px 20px 25px;
}

.progress-steps{
    text-align: center;
    font-weight: 600;
    font-size: 21px;
}

.progress-steps p{
    margin: 10px 0;
}

.progress-bar{
    background-color: rgb(184, 184, 184);
    width: 100%;
    height: 15px;
    border-radius: 20px;
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;
}

.progress-bar-inner{
    background-color: rgb(38, 187, 75);
    height: 15px;
}