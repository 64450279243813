.form-wrap{
    max-width: 800px;
    margin: 0 auto;
}

.form-wrap .form-input-handler{
    margin-bottom: 25px;
    max-width: 500px;
    margin: 0 auto;
}

.form-wrap .question-headline{
    font-size: 31px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
}


.form-wrap .form-input-handler .form-input-item{
    margin-top: 20px;
}

.form-wrap .form-input-handler .form-input-item input{
    width: calc(100% - 40px);
    height: 53px;
    font-size: 17px;
    padding: 10px;
    color: #000000;
    border: 1px solid rgb(121, 121, 121);
}

.form-wrap .form-input-handler .form-input-item label{
    margin-bottom: 5px;
    color: #000;
    font-size: 14px;
    display: block;
    font-weight: 600;
}

.form-wrap .form-input-handler .form-input-item div{
    width: 100%;
    margin-bottom: 20px;
}


.submit-handler button{
    background-color: #9ee20b;
    border: none;
    padding: 20px 20px;
    font-size: 20px;
    border-radius: 23px;
    color: #000;
    font-weight: 300;
}



.submit-handler{
    text-align: center;
}


.form-copy{
    font-size: 12px;
}




